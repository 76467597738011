.dagsplan p {
    font-family: 'Open Sans', sans-serif !important;
}

.timeline-dot {
    background-color: rgb(86, 117, 89) !important;
}

.timeline-icon {
    color: rgb(194, 205, 176);
}

.timeline-line {
    background-color: rgb(86, 117, 89) !important;
}