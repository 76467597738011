.image-page p {
    font-family: 'Open Sans', sans-serif;
}

.image-page h2 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: larger;
}

.image-bilder img {
    border-radius: 3%;
    margin: 2%;
    max-width: 20vw;
}

.image-page video {
    width: 100%;
}

@media only screen and (max-width: 1024px) {
    .image-bilder img {
        max-width: 65vw;
    }
}