.maps-div {
    width: 100%;
    height: 50vh;
    justify-content: center;
    z-index: 2;
    position: relative;
}

@media only screen and (max-width: 1024px) {
	.maps-div {
        transform: translate(-15%, 0);
        width: 100vw;
        height: 50vh;
    }
}