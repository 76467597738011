@import url('https://fonts.googleapis.com/css2?family=Allura&family=Cookie&family=Dancing+Script:wght@500&family=Explora&family=Great+Vibes&family=Herr+Von+Muellerhoff&family=Italianno&family=Parisienne&family=Playball&family=Playfair+Display:ital,wght@1,500&family=Sacramento&family=Tangerine:wght@700&display=swap');

.forside {
    min-width: 100vw;
    min-height: 90vh;
    max-height: 90vh;
    background-image: url(../../images/gausti-webp.webp);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
}

.image-container {
    justify-content: center;
}

.forside-overskrift {
    font-size: 7.5rem;
    color: rgb(255, 252, 235);
    font-family: 'Playfair Display', serif;
    letter-spacing: 0.88px;
}

.forside-dato {
    letter-spacing: 1px;
    font-size: 1.3rem;
    color: rgb(255, 252, 235);
    font-family: 'Open Sans', sans-serif;
}

.forside-undertekst {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
}

@media only screen and (max-width: 1024px) {
    .forside-overskrift {
        padding: 10%;
        font-size: 6rem;
    }

    .forside {
        background-size: cover;
    }
}