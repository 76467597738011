
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
	font-size: 1.02em;
}

:root {
	--mainColor: rgb(181, 195, 159);
	--mainColorLight: #62aa57;
	--secondaryColor: rgb(181, 108, 138);
	--textColor: rgb(35, 34, 30);
}

header {
	position: sticky;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 10vh;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
	z-index: 10;
}

nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 0.5s;
		transform: translateY(-100vh);
	}
	
	header .responsive_nav {
		transform: none;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
}

.navbar-icon {
	width: 50px;
	height: auto;
}
