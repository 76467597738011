@import url('https://fonts.googleapis.com/css2?family=Italianno&family=Open+Sans:wght@400;700&family=Playfair+Display:wght@400;500&display=swap');

html {
  background-color: rgb(194, 205, 176);
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.content {
  display: flex;
  justify-content: space-between;
  min-height: 90vh;
}

.sidebar-image {
  height: 20vh;
  max-width: 100%;
}

.sidebar {
  min-width: 15vw;
  background-repeat: repeat-y;
  background-size: 60%;
  z-index: 1;
}

.sidebar-left {
  background-image: url(images/blomster_venstre.svg);
}
.sidebar-right {
  background-position: right;
  background-image: url(images/blomster_hoyre.svg);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
}

h1 {
  font-size: 3rem;
  color: rgb(255, 252, 235);
  font-family: 'Playfair Display', serif;
  letter-spacing: 3px;
}

p {
  font-family: 'Open Sans', sans-serif;
}

.page-content {
  max-width: 50vw;
  text-align: left;
  line-height: 1.69;
}

@media only screen and (max-width: 1024px) {
  .page-content {
      max-width: 70vw;
  }

  h1 {
    font-size: 2rem;
  }
}

a {
  text-decoration: none;
  color: darkgreen;
}