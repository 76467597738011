
.informasjon p {
    font-family: 'Open Sans', sans-serif;
}

.informasjon hr {
    border: 1px solid rgb(86, 117, 89);
    margin: 1.5rem 0 0.5rem 0;
}

.info-bilder {
    padding-top: 3%;
    width: 100%;
}

.info-bilder img {
    border-radius: 3%;
    width: 100%;
}

@media only screen and (max-width: 1024px) {
	.info-bilder img {
        transform: translate(-15%, 0);
        width: 100vw;
        position: absolute;
    }

    .info-bilder {
        padding-top: 0;
    }
}